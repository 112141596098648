@font-face {
  font-family: "Urbane";
  src: url("../font/Urbane-LightItalic.eot");
  src: local("Urbane Light Italic"), local("Urbane-LightItalic"),
    url("../font/Urbane-LightItalic.eot?#iefix") format("embedded-opentype"),
    url("../font/Urbane-LightItalic.woff2") format("woff2"),
    url("../font/Urbane-LightItalic.woff") format("woff"),
    url("../font/Urbane-LightItalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Urbane";
  src: url("../font/Urbane-Medium.eot");
  src: local("Urbane Medium"), local("Urbane-Medium"),
    url("../font/Urbane-Medium.eot?#iefix") format("embedded-opentype"),
    url("../font/Urbane-Medium.woff2") format("woff2"),
    url("../font/Urbane-Medium.woff") format("woff"),
    url("../font/Urbane-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Urbane";
  src: url("../font/Urbane-ThinItalic.eot");
  src: local("Urbane Thin Italic"), local("Urbane-ThinItalic"),
    url("../font/Urbane-ThinItalic.eot?#iefix") format("embedded-opentype"),
    url("../font/Urbane-ThinItalic.woff2") format("woff2"),
    url("../font/Urbane-ThinItalic.woff") format("woff"),
    url("../font/Urbane-ThinItalic.ttf") format("truetype");
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Urbane Demi";
  src: url("../font/Urbane-DemiBold.eot");
  src: local("Urbane Demi Bold"), local("Urbane-DemiBold"),
    url("../font/Urbane-DemiBold.eot?#iefix") format("embedded-opentype"),
    url("../font/Urbane-DemiBold.woff2") format("woff2"),
    url("../font/Urbane-DemiBold.woff") format("woff"),
    url("../font/Urbane-DemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Urbane";
  src: url("../font/Urbane-Heavy.eot");
  src: local("Urbane Heavy"), local("Urbane-Heavy"),
    url("../font/Urbane-Heavy.eot?#iefix") format("embedded-opentype"),
    url("../font/Urbane-Heavy.woff2") format("woff2"),
    url("../font/Urbane-Heavy.woff") format("woff"),
    url("../font/Urbane-Heavy.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Urbane";
  src: url("../font/Urbane-Thin.eot");
  src: local("Urbane Thin"), local("Urbane-Thin"),
    url("../font/Urbane-Thin.eot?#iefix") format("embedded-opentype"),
    url("../font/Urbane-Thin.woff2") format("woff2"),
    url("../font/Urbane-Thin.woff") format("woff"),
    url("../font/Urbane-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Urbane";
  src: url("../font/Urbane-BoldItalic.eot");
  src: local("Urbane Bold Italic"), local("Urbane-BoldItalic"),
    url("../font/Urbane-BoldItalic.eot?#iefix") format("embedded-opentype"),
    url("../font/Urbane-BoldItalic.woff2") format("woff2"),
    url("../font/Urbane-BoldItalic.woff") format("woff"),
    url("../font/Urbane-BoldItalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Urbane Extra";
  src: url("../font/Urbane-ExtraLight.eot");
  src: local("Urbane Extra Light"), local("Urbane-ExtraLight"),
    url("../font/Urbane-ExtraLight.eot?#iefix") format("embedded-opentype"),
    url("../font/Urbane-ExtraLight.woff2") format("woff2"),
    url("../font/Urbane-ExtraLight.woff") format("woff"),
    url("../font/Urbane-ExtraLight.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Urbane";
  src: url("../font/Urbane-HeavyItalic.eot");
  src: local("Urbane Heavy Italic"), local("Urbane-HeavyItalic"),
    url("../font/Urbane-HeavyItalic.eot?#iefix") format("embedded-opentype"),
    url("../font/Urbane-HeavyItalic.woff2") format("woff2"),
    url("../font/Urbane-HeavyItalic.woff") format("woff"),
    url("../font/Urbane-HeavyItalic.ttf") format("truetype");
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Urbane";
  src: url("../font/Urbane-Bold.eot");
  src: local("Urbane Bold"), local("Urbane-Bold"),
    url("../font/Urbane-Bold.eot?#iefix") format("embedded-opentype"),
    url("../font/Urbane-Bold.woff2") format("woff2"),
    url("../font/Urbane-Bold.woff") format("woff"),
    url("../font/Urbane-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Urbane";
  src: url("../font/Urbane-MediumItalic.eot");
  src: local("Urbane Medium Italic"), local("Urbane-MediumItalic"),
    url("../font/Urbane-MediumItalic.eot?#iefix") format("embedded-opentype"),
    url("../font/Urbane-MediumItalic.woff2") format("woff2"),
    url("../font/Urbane-MediumItalic.woff") format("woff"),
    url("../font/Urbane-MediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Urbane Demi";
  src: url("../font/Urbane-DemiBoldItalic.eot");
  src: local("Urbane Demi Bold Italic"), local("Urbane-DemiBoldItalic"),
    url("../font/Urbane-DemiBoldItalic.eot?#iefix") format("embedded-opentype"),
    url("../font/Urbane-DemiBoldItalic.woff2") format("woff2"),
    url("../font/Urbane-DemiBoldItalic.woff") format("woff"),
    url("../font/Urbane-DemiBoldItalic.ttf") format("truetype");
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Urbane";
  src: url("../font/Urbane-Light.eot");
  src: local("Urbane Light"), local("Urbane-Light"),
    url("../font/Urbane-Light.eot?#iefix") format("embedded-opentype"),
    url("../font/Urbane-Light.woff2") format("woff2"),
    url("../font/Urbane-Light.woff") format("woff"),
    url("../font/Urbane-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Urbane Extra";
  src: url("../font/Urbane-ExtraLightItalic.eot");
  src: local("Urbane Extra Light Italic"), local("Urbane-ExtraLightItalic"),
    url("../font/Urbane-ExtraLightItalic.eot?#iefix") format("embedded-opentype"),
    url("../font/Urbane-ExtraLightItalic.woff2") format("woff2"),
    url("../font/Urbane-ExtraLightItalic.woff") format("woff"),
    url("../font/Urbane-ExtraLightItalic.ttf") format("truetype");
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

/*!

 =========================================================
 * Material Dashboard React - v1.10.0 based on Material Dashboard - v1.2.0
 =========================================================

 * Product Page: http://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2021 Creative Tim (http://www.creative-tim.com)
 * Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

 */
html,
body,
#root,
.h_100per {
  height: 100%;
  word-wrap: break-word;
}

.ct-grid {
  stroke: rgba(255, 255, 255, 0.2);
  stroke-width: 1px;
  stroke-dasharray: 2px;
}

.ct-series-a .ct-point,
.ct-series-a .ct-line,
.ct-series-a .ct-bar,
.ct-series-a .ct-slice-donut {
  stroke: rgba(255, 255, 255, 0.8);
}

.ct-label.ct-horizontal.ct-end {
  -webkit-box-align: flex-start;
  -webkit-align-items: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
  -webkit-box-pack: flex-start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  text-align: left;
  text-anchor: start;
}

.ct-label {
  color: rgba(255, 255, 255, 0.7);
}

.ct-chart-line .ct-label,
.ct-chart-bar .ct-label {
  display: block;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

.ct-label {
  fill: rgba(0, 0, 0, 0.4);
  line-height: 1;
}

html * {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  background-color: #edf1f4;
  color: #3c4858;
  margin: 0;
  font-size: 13px;
  /* font-family: Roboto, Helvetica, Arial, sans-serif; */
  font-family: "Urbane" !important;
  font-weight: 300;
  line-height: 1.5em;
}

blockquote footer:before,
blockquote small:before {
  content: "\2014 \00A0";
}

small {
  font-size: 80%;
}

h1 {
  font-size: 3em;
  line-height: 1.15em;
}

h2 {
  font-size: 2.4em;
}

h3 {
  font-size: 1.825em;
  line-height: 1.4em;
  margin: 20px 0 10px;
}

h4 {
  font-size: 1.3em;
  line-height: 1.4em;
}

h5 {
  font-size: 1.25em;
  line-height: 1.4em;
  margin-bottom: 15px;
}

h6 {
  font-size: 1em;
  text-transform: uppercase;
  font-weight: 500;
}

body {
  background-color: #edf1f4;
  color: #3c4858;
}

blockquote p {
  font-style: italic;
}

body,
h1,
h2,
h3,
h4,
h5,
h6 {
  /* font-family: "Roboto", "Helvetica", "Arial", sans-serif; */
  font-family: "Urbane" !important;
  font-weight: 300;
  line-height: 1.5em;
}

a {
  color: #9c27b0;
  text-decoration: none;
}

a:hover,
a:focus {
  color: #89229b;
  text-decoration: none;
}

legend {
  border-bottom: 0;
}

* {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  -webkit-tap-highlight-color: transparent;
}

*:focus {
  outline: 0;
}

a:focus,
a:active,
button:active,
button:focus,
button:hover,
button::-moz-focus-inner,
input[type="reset"]::-moz-focus-inner,
input[type="button"]::-moz-focus-inner,
input[type="submit"]::-moz-focus-inner,
select::-moz-focus-inner,
input[type="file"]>input[type="button"]::-moz-focus-inner {
  outline: 0 !important;
}

legend {
  margin-bottom: 20px;
  font-size: 21px;
}

output {
  padding-top: 8px;
  font-size: 14px;
  line-height: 1.42857;
}

label {
  font-size: 14px;
  line-height: 1.42857;
  color: #aaaaaa;
  font-weight: 400;
}

footer {
  padding: 15px 0;
}

footer ul {
  margin-bottom: 0;
  padding: 0;
  list-style: none;
}

footer ul li {
  display: inline-block;
}

footer ul li a {
  color: inherit;
  padding: 15px;
  font-weight: 500;
  font-size: 12px;
  text-transform: uppercase;
  border-radius: 3px;
  text-decoration: none;
  position: relative;
  display: block;
}

footer ul li a:hover {
  text-decoration: none;
}

/* Add collaborator */
.addCollaborator {
    top: 50px!important;
    right: 0;
    /* left: "unset"; */

}
.pricing-plan{
  margin-bottom: 3rem !important;
}

@media (max-width: 991px) {

  body,
  html {
    position: relative;
    overflow-x: hidden;
  }

  #bodyClick {
    height: 100%;
    width: 100%;
    position: fixed;
    opacity: 0;
    top: 0;
    left: auto;
    right: 260px;
    content: "";
    z-index: 9999;
    overflow-x: hidden;
  }
  .pricing-plan{
    margin-bottom: 0rem !important;
  }
}

.fixed-plugin {
  /* font-family: "Roboto", "Helvetica", "Arial", sans-serif; */
  font-family: "Urbane" !important;
  font-weight: 300;
  line-height: 1.5em;
  position: fixed;
  top: 180px;
  right: 0;
  width: 64px;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 1031;
  border-radius: 8px 0 0 8px;
  text-align: center;
  top: 120px;
}

.fixed-plugin .SocialMediaShareButton,
.fixed-plugin .github-btn {
  display: inline-block;
}

.fixed-plugin li>a,
.fixed-plugin .badge {
  transition: all 0.34s;
  -webkit-transition: all 0.34s;
  -moz-transition: all 0.34s;
  text-decoration: none;
}

.fixed-plugin .fa-cog {
  color: #ffffff;
  padding: 10px;
  border-radius: 0 0 6px 6px;
  width: auto;
}

.fixed-plugin .dropdown-menu {
  right: 80px;
  left: auto;
  width: 290px;
  border-radius: 0.1875rem;
  padding: 0 10px;
  position: absolute;
  color: rgba(0, 0, 0, 0.87);
  display: inline-block;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
  background: #fff;
  border-radius: 3px;
}

.fixed-plugin .fa-circle-thin {
  color: #ffffff;
}

.fixed-plugin .active .fa-circle-thin {
  color: #00bbff;
}

.fixed-plugin .dropdown-menu>.active>a,
.fixed-plugin .dropdown-menu>.active>a:hover,
.fixed-plugin .dropdown-menu>.active>a:focus {
  color: #777777;
  text-align: center;
}

.fixed-plugin img {
  border-radius: 0;
  width: 100%;
  height: 100px;
  margin: 0 auto;
}

.fixed-plugin .dropdown-menu li>a:hover,
.fixed-plugin .dropdown-menu li>a:focus {
  box-shadow: none;
}

.fixed-plugin .badge {
  border: 3px solid #ffffff;
  border-radius: 50%;
  cursor: pointer;
  display: inline-block;
  height: 23px;
  margin-right: 5px;
  position: relative;
  width: 23px;
  background-color: rgba(30, 30, 30, 0.97);
}

.fixed-plugin .badge.active,
.fixed-plugin .badge:hover {
  border-color: #00bbff;
}

.fixed-plugin .badge-purple {
  background-color: #9c27b0;
}

.fixed-plugin .badge-blue {
  background-color: #00bcd4;
}

.fixed-plugin .badge-green {
  background-color: #4caf50;
}

.fixed-plugin .badge-orange {
  background-color: #ff9800;
}

.fixed-plugin .badge-red {
  background-color: #f44336;
}

.fixed-plugin h5 {
  font-size: 14px;
  margin: 10px;
}

.fixed-plugin .dropdown-menu li {
  display: block;
  padding: 4px 0px;
  width: 25%;
  float: left;
}

.fixed-plugin li.adjustments-line,
.fixed-plugin li.header-title,
.fixed-plugin li.button-container {
  width: 100%;
  height: 50px;
  min-height: inherit;
  padding: 0px;
  text-align: center;
}

.fixed-plugin li.adjustments-line p {
  margin: 0;
}

.fixed-plugin li.adjustments-line div,
.fixed-plugin li.header-title div,
.fixed-plugin li.button-container div {
  margin-bottom: 5px;
}

.fixed-plugin li.header-title {
  height: 30px;
  line-height: 25px;
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
}

.fixed-plugin .adjustments-line p {
  float: left;
  display: inline-block;
  margin-bottom: 0;
  font-size: 1em;
  color: #3c4858;
}

.fixed-plugin .adjustments-line a {
  color: transparent;
}

.fixed-plugin .adjustments-line a .badge-colors {
  position: relative;
  top: -2px;
}

.fixed-plugin .adjustments-line a a:hover,
.fixed-plugin .adjustments-line a a:focus {
  color: transparent;
}

.fixed-plugin .adjustments-line .dropdown-menu>li.adjustments-line>a {
  padding-right: 0;
  padding-left: 0;
  border-bottom: 1px solid #ddd;
  border-radius: 0;
  margin: 0;
}

.fixed-plugin .dropdown-menu>li>a.img-holder {
  font-size: 16px;
  text-align: center;
  border-radius: 10px;
  background-color: #fff;
  border: 3px solid #fff;
  padding-left: 0;
  padding-right: 0;
  opacity: 1;
  cursor: pointer;
  display: block;
  max-height: 100px;
  overflow: hidden;
  padding: 0;
}

.fixed-plugin .dropdown-menu>li>a.img-holder img {
  margin-top: auto;
}

.fixed-plugin .dropdown-menu>li:hover>a.img-holder,
.fixed-plugin .dropdown-menu>li:focus>a.img-holder {
  border-color: rgba(0, 187, 255, 0.53);
}

.fixed-plugin .dropdown-menu>.active>a.img-holder,
.fixed-plugin .dropdown-menu>.active>a.img-holder {
  border-color: #00bbff;
  background-color: #ffffff;
}

.fixed-plugin .dropdown .dropdown-menu {
  -webkit-transform: translateY(-15%);
  -moz-transform: translateY(-15%);
  -o-transform: translateY(-15%);
  -ms-transform: translateY(-15%);
  transform: translateY(-15%);
  top: 27px;
  opacity: 0;
  transform-origin: 0 0;
  display: none;
}

.fixed-plugin .dropdown .dropdown-menu:before {
  border-bottom: 0.4em solid transparent;
  border-left: 0.4em solid rgba(0, 0, 0, 0.2);
  border-top: 0.4em solid transparent;
  right: -16px;
  top: 46px;
}

.fixed-plugin .dropdown .dropdown-menu:after {
  border-bottom: 0.4em solid transparent;
  border-left: 0.4em solid #ffffff;
  border-top: 0.4em solid transparent;
  right: -16px;
}

.fixed-plugin .dropdown .dropdown-menu:before,
.fixed-plugin .dropdown .dropdown-menu:after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 46px;
  width: 16px;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
}

.fixed-plugin .dropdown.show .dropdown-menu {
  display: block;
  visibility: visible;
  opacity: 1;
  -webkit-transform: translateY(-13%);
  -moz-transform: translateY(-13%);
  -o-transform: translateY(-13%);
  -ms-transform: translateY(-13%);
  transform: translateY(-13%);
  transform-origin: 0 0;
}

.fixed-plugin.rtl-fixed-plugin {
  right: auto;
  left: 0px;
  border-radius: 0 8px 8px 0;
}

.fixed-plugin.rtl-fixed-plugin .dropdown-menu {
  right: auto;
  left: 80px;
}

* {
  letter-spacing: normal !important;
}

/* my style */
#security-question .MuiSelect-iconOutlined {
  right: 0;
}

#security-question .MuiSelect-icon {
  top: 0;
}

#security-question .MuiSvgIcon-root {
  height: 100%;
  font-size: 34px;
  background: #ffb722;
  fill: #fff;
  padding: 0 6px;
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
}

.authBg {
  background: url(../img/authbg.png);
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: top left;
  background-color: #fff;
}

.d-flex {
  display: flex;
}

.align-items-end {
  align-items: flex-end;
}

.history_main div:last-child .position-absolute.h-100 {
  display: none;
}

.textAreaStyle {
  border: 1px solid rgba(0, 0, 0, 0.23) !important;
  border-color: rgba(0, 0, 0, 0.23) !important;
  border-radius: 4px;
  padding: 10px 12px 8px !important;
  margin: 0 !important;
}

.textAreaStyle ::before,
.textAreaStyle ::after {
  border: 0 !important;
}

#security-question fieldset,
.fieldTopZero fieldset {
  top: 0;
}

#arrow_box {
  position: relative;
  background: #ffffff;
  padding: 30px 0;
  margin-bottom: 100px;
}

#arrow_box.arrowTop:after,
#arrow_box.arrowTop:before {
  top: auto;
  bottom: 40px;
}

#arrow_box.financearrowTop:after,
#arrow_box.financearrowTop:before {
  top: 350px;
}

#arrow_box.insurancearrowTop:after,
#arrow_box.insurancearrowTop:before {
  top: 387px;
}

#arrow_box:after,
#arrow_box:before {
  right: 100%;
  top: 73px;
  border: solid transparent;
  content: "";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

#arrow_box:after {
  border-color: rgba(136, 183, 213, 0);
  border-right-color: #ffffff;
  border-width: 10px;
  margin-top: -30px;
}

#arrow_box:before {
  border-color: rgba(194, 225, 245, 0);
  border-right-color: #ffffff;
  border-width: 18px;
  margin-top: -36px;
}

.favoriteCat {
  font-size: 16px !important;
  top: 8px;
  right: 15px;
}

/* #click-to-expand-main .click-to-expand {
  display: none;
  transition-duration: 2s;
}

#click-to-expand-main:hover .click-to-expand {
  display: block;
  background: #1a364aa1;
} */

/* Scrollbar Custom Style */
/* width */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  border-radius: 6px;
  transition: background-color 0.2s linear, width 0.2s ease-in-out;
  -webkit-transition: background-color 0.2s linear, width 0.2s ease-in-out;
}

/* Track */
::-webkit-scrollbar-track {
  background: lightgray;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #060d5c;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.drag_and_upload {
  background: #edf1f4;
  border: 2px dashed #000000;
  border-radius: 15px;
  min-height: 130px;
}

.brows-file {
  left: 25%;
  bottom: 25px;
  z-index: 10000;
}

#darkautofill>input:-webkit-autofill {
  -webkit-text-fill-color: #000 !important;
}

#darkautofill input:-webkit-autofill,
#darkautofill input:-webkit-autofill:hover,
#darkautofill input:-webkit-autofill:focus,
#darkautofill input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: #000 !important;
}

.black-text {
  color: #000 !important;
}

.black-text:hover {
  color: #0042a8 !important;
}

.orange-text {
  color: #0042a8 !important;
}

.animatedLogo {
  display: block;
  margin: 0 auto;
}

.animatedLogo,
.animatedLogo:hover {
  animation: wobble 1s 1;
}

@keyframes wobble {
  25% {
    transform: rotate(15deg);
  }

  50% {
    transform: rotate(-30deg);
  }

  75% {
    transform: rotate(5deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

.loginAnimationWrapper {
  max-width: 150px;
  margin: 0 auto;
}

.bgTransparent {
  height: 100% !important;
  justify-content: center;
  align-items: center;
}

.bgTransparent>div {
  background: transparent;
}

.errorPositioning {
  position: absolute;
  left: 12px;
  bottom: -10px;
}

.fav_width {
  width: 19%;
  margin-right: 1%;
  height: 100%;
}

.tabsMain1 .MuiTabs-indicator {
  display: none;
}

.dashboardTabsBtn {
  min-width: fit-content !important;
}

.dashboardTabsBtn>.MuiTab-wrapper {
  align-items: flex-start;
}

.tabsContent .MuiBox-root {
  padding: 0;
}

/*
.smallCircle::before {
    content: "•";
    font-size: 35px;
    line-height: 17px;
    color: #0042a8;
    margin-right: 25px;
} */

.dropMenu {
  top: 0;
  left: -20px !important;
}

.dropMenu ul {
  padding: 0;
}

.dropMenuDocumentMain {
  top: 32px;
  right: 20px;
  width: fit-content;
}

.dropMenuDocument {
  left: unset !important;
  top: 30px !important;
  right: 20px !important;
  /* width: fit-content; */
}

.bg_white {
  background-color: #fff !important;
  position: relative;
  z-index: 1000;
}

.dropMenuDocument ul {
  padding: 0;
}

.topZero fieldset {
  top: 0;
}

.wFill_available {
  width: -webkit-fill-available;
}

.backgroundDelay {
  transition-property: background-color, font-size, transform, color;
  transition-timing-function: ease-in-out;
  /* transition-duration: 3s; */
  /* transition-delay: 4s; */
  /* transition-duration: 4s!important; */
  transition-delay: 0.5s !important;
  /* transition: background 4s!important; */
  /* transition-property: background!important; */
}

.scrollWidthZero::-webkit-scrollbar,
.tabsMain1 .MuiTabs-fixed::-webkit-scrollbar {
  width: 0;
  height: 0;
}

video::-internal-media-controls-download-button {
  display: none;
}

video::-webkit-media-controls-enclosure {
  overflow: hidden;
}

video::-webkit-media-controls-panel {
  width: calc(100% + 30px);
  /* Adjust as needed */
}

.w_1100px {
  width: 1050px !important;
}

.phoneInput input {
  width: 100% !important;
  height: 45px !important;
}

.phoneInput .form-control:focus {
  box-shadow: none !important;
  border-color: navy !important;
}

.h_100 {
  height: 100%;
}

.theme_bg {
  background-color: #051c2c;
}

.fadeOpacity {
  opacity: 0.6;
}

/* .tooltipWidth {
    width: 250px;
    word-wrap: break-word;
} */

.tooltipText {
  all: unset;
  padding: 10px 20px;
  font-size: 0.75rem;
  border-radius: 10px;

  gap: 10px;
  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
  user-select: none;
  white-space: nowrap;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.tooltipText {
  background: rgba(55, 55, 55, 1);
  color: #ffffff;
}

.tooltipText:hover {
  background: rgba(55, 55, 55, 1);
  color: #ffffff;
  transition: 0.3s;
}

/* Title attribute as tooltip */
[data-tooltip] {
  position: relative;
  word-wrap: break-word;
  /* z-index: 100 !important; */
}

/* Positioning and visibility settings of the tooltip */
[data-tooltip]::before,
[data-tooltip]::after {
  position: absolute;
  left: 50%;
  bottom: calc(100% + 5px);
  /* 5px is the size of the arrow */

  opacity: 0;
  visibility: hidden;
  pointer-events: none;

  transition: 0.3s;
  will-change: transform;
}

/* The actual tooltip with a dynamic width */
[data-tooltip]::before {
  z-index: 100000;
  content: attr(data-tooltip);

  min-width: 50px;
  max-width: 300px;
  width: max-content;
  width: -moz-max-content;

  padding: 10px 18px;
  border-radius: 10px;
  background-color: rgba(50, 50, 50, 1);
  box-shadow: var(--default-box-shadow);

  color: #fff;
  font-size: 0.75rem;
  text-align: center;
  white-space: pre-wrap;

  transform: translate(-50%, -5px) scale(0.5);
}

/* Tooltip arrow */
[data-tooltip]::after {
  content: "";
  border-style: solid;
  border-width: 5px 5px 0 5px;
  /* CSS triangle */
  border-color: rgba(50, 50, 50, 1) transparent transparent transparent;

  transform-origin: top;
  /* Orientation setting for the slide-down effect */
  transition-duration: 0s;
  /* If the mouse leaves the element, the transition effects for the tooltip arrow are "turned off" */
  transform: translateX(-50%) scaleY(0);
}

/* Tooltip becomes visible at hover */
[data-tooltip]:hover::before,
[data-tooltip]:hover::after {
  visibility: visible;
  opacity: 1;
}

/* Scales from 0.5 to 1 -> grow effect */
[data-tooltip]:hover::before {
  transition-delay: 0.3s;
  transform: translate(-50%, -5px) scale(1);
}

/* Arrow slide down effect only on mouseenter (NOT on mouseleave) */
[data-tooltip]:hover::after {
  transition-delay: 0.5s;
  /* Starting after the grow effect */
  transition-duration: 0.2s;
  transform: translateX(-50%) scaleY(1);
}

/* *** That's it for the basic tooltip. If you want some adjustability here are some orientation settings you can use: *** */

/* LEFT */
/* Tooltip + arrow */
/* [data-tooltip-location="left"]::before,
[data-tooltip-location="left"]::after {
    left: auto;
    bottom: 50%;
    right: calc(100% + 5px);
} */
/* Tooltip */
[data-tooltip-location="left"]::before {
  transform: translate(-5px, 50%) scale(0.5);
}

[data-tooltip-location="left"]:hover::before {
  transform: translate(-5px, 50%) scale(1);
}

/* Arrow */
[data-tooltip-location="left"]::after {
  border-width: 5px 0 5px 5px;
  border-color: transparent transparent transparent rgba(50, 50, 50, 1);
  transform-origin: left;
  transform: translateY(50%) scaleX(0);
}

[data-tooltip-location="left"]:hover::after {
  transform: translateY(50%) scaleX(1);
}

/* RIGHT */
/* [data-tooltip-location="right"]::before,
[data-tooltip-location="right"]::after {
    bottom: 50%;
    left: calc(100% + 5px);
}
[data-tooltip-location="right"]::before {
    transform: translate(5px, 50%) scale(0.5);
}
[data-tooltip-location="right"]:hover::before {
    transform: translate(5px, 50%) scale(1);
}
[data-tooltip-location="right"]::after {
    border-width: 5px 5px 5px 0;
    border-color: transparent rgba(50, 50, 50, 1) transparent transparent;
    transform-origin: right;
    transform: translateY(50%) scaleX(0);
}
[data-tooltip-location="right"]:hover::after {
    transform: translateY(50%) scaleX(1);
} */

/* BOTTOM */
[data-tooltip-location="bottom"]::before,
[data-tooltip-location="bottom"]::after {
  top: calc(100% + 5px);
  bottom: auto;
}

[data-tooltip-location="bottom"]::before {
  transform: translate(-50%, 5px) scale(0.5);
}

[data-tooltip-location="bottom"]:hover::before {
  transform: translate(-50%, 5px) scale(1);
}

[data-tooltip-location="bottom"]::after {
  transform-origin: bottom;
  border-width: 0 5px 5px 5px;
  border-color: transparent transparent rgba(50, 50, 50, 1) transparent;
}

.editDeleIcons .hoverShow {
  display: none;
  z-index: 100000;
}

.editDeleIcons:hover .hoverShow {
  display: block;
  z-index: 1000000;
}

.top-15 {
  top: -13px;
}

.h-90 {
  height: 90% !important;
}

.alignContentStart {
  align-content: flex-start;
}

/* video control hide */
*::-webkit-media-controls-panel {
  display: none !important;
  -webkit-appearance: none;
}

/* Old shadow dom for play button */

*::--webkit-media-controls-play-button {
  display: none !important;
  -webkit-appearance: none;
}

/* New shadow dom for play button */

/* This one works */

*::-webkit-media-controls-start-playback-button {
  display: none !important;
  -webkit-appearance: none;
}

/* IOS changed */
video::slotted::-webkit-media-controls-container {
  display: none !important;
  visibility: hidden !important;
  opacity: 0 !important;
  -webkit-appearance: none !important;
}

/* other video controls hidden */

*::-webkit-media-controls-panel {
  display: none !important;
  -webkit-appearance: none;
}

/* Old shadow dom for play button */

*::--webkit-media-controls-play-button {
  display: none !important;
  -webkit-appearance: none;
}

/* New shadow dom for play button */

/* This one works */

*::-webkit-media-controls-start-playback-button {
  display: none !important;
  -webkit-appearance: none;
}

/* video control hide */

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.placeholderText,
.placeholderText::placeholder {
  text-align: center;
}

.bg_yellow {
  background: #ffb722 !important;
}

.color_yellow {
  color: #ffb722 !important;
}

.color_dark {
  color: #060d5c !important;
}

@media only screen and (max-width: 1054px) {
  .fav_width {
    width: 24%;
  }

  .wFill_available {
    width: auto;
  }

  .h_100 {
    height: auto;
  }
}

.text-sm-center {
  text-align: right !important;
}

.mb-sm-2 {
  margin-bottom: 0 !important;
}

.d_xs_none {
  display: block !important;
}

@media only screen and (max-width: 959px) {
  .bgWhiteMobile {
    background-color: #fff;
  }
}

@media only screen and (max-width: 767px) {

  #arrow_box:after,
  #arrow_box:before {
    right: 99%;
  }

  /* #arrow_box:after, #arrow_box:before {
        border-left-color: #00BED6;
        border-right-color: transparent;
    } */
  .text-sm-center {
    text-align: center !important;
  }

  .mb-sm-2 {
    margin-bottom: 8px !important;
  }

  #root .hieghtInMobile {
    height: 100%;
  }

  .d-xs-none,
  .d_xs_none {
    display: none !important;
  }

  .fav_width {
    width: 48%;
    margin-right: 2%;
  }

  .fav_width:nth-child(even) {
    margin-right: 0;
  }

  .tabsMain1 .MuiTabs-fixed {
    overflow-x: auto !important;
  }

  .position-sm-static {
    position: static !important;
  }

  .h_100 {
    height: auto;
  }

  .bgWhiteMobile {
    background-color: #fff;
  }

  .translate3d {
    top: 15px !important;
    width: 90%;
    transform: translate3d(15px, 115px, 0px) !important;
  }

  .linked_document_main {
    right: -110px !important;
  }
}

@media screen and (max-width: 400px) and (min-width: 280px) {
  .linked_document_main {
    right: -50px !important;
  }
}

@media only screen and (max-width: 480px) {
  .fav_width {
    width: 100%;
    margin-right: 0;
    padding: 0 15px;
  }

  .bgWhiteMobile {
    background-color: #fff;
  }

  .d_xs_none {
    display: none !important;
  }
}

.tabsMain1 a:hover,
.tabsMain1 a:focus {
  color: unset !important;
}

.text_capitalize:first-letter {
  text-transform: capitalize !important;
}

#imgResponsive {
  height: 100%;
  width: 100%;
}

.responsive-iframe {
  max-width: 100%;
  width: 100%;
  height: 600px;
}

.responsive-iframe #imgResponsive body img {
  height: 100% !important;
}

.zIndex {
  z-index: 1000000;
}

.heighZIndex {
  z-index: 1000000000000;
}

.fav_btn_focus {
  background: #0042a8;
}

.min-height-182px {
  min-height: 182px;
}

.max-height-250px {
  max-height: 250px;
  max-width: 250px;
  overflow-y: scroll;
}

.datepicker .MuiOutlinedInput-input {
  padding: 10.5px 14px;
}

#security-question .datepicker .MuiSvgIcon-root {
  background: transparent;
  fill: #000;
}

#security-question .datepicker .MuiIconButton-root {
  padding-left: 0;
  padding-right: 0;
}

.black-input-color div input {
  color: black !important;
}

.subCatCount {
  right: 15px;
  top: 16px !important;
  color: #fff;
  font-size: 12px;
  font-weight: 700;
}

.colorLightGrey div {
  color: #a3a3a3;
}

.bottomRadius {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.bell {
  display: block;
  width: 40px;
  height: 40px;
  font-size: 40px;
  margin: 50px auto 0;
  color: #9e9e9e;
  -webkit-animation: ring 5s 0.7s ease-in-out infinite;
  -webkit-transform-origin: 50% 4px;
  -moz-animation: ring 5s 0.7s ease-in-out infinite;
  -moz-transform-origin: 50% 4px;
  animation: ring 5s 0.7s ease-in-out infinite;
  transform-origin: 50% 4px;
}

@-webkit-keyframes ring {
  0% {
    -webkit-transform: rotateZ(0);
  }

  1% {
    -webkit-transform: rotateZ(30deg);
  }

  3% {
    -webkit-transform: rotateZ(-28deg);
  }

  5% {
    -webkit-transform: rotateZ(34deg);
  }

  7% {
    -webkit-transform: rotateZ(-32deg);
  }

  9% {
    -webkit-transform: rotateZ(30deg);
  }

  11% {
    -webkit-transform: rotateZ(-28deg);
  }

  13% {
    -webkit-transform: rotateZ(26deg);
  }

  15% {
    -webkit-transform: rotateZ(-24deg);
  }

  17% {
    -webkit-transform: rotateZ(22deg);
  }

  19% {
    -webkit-transform: rotateZ(-20deg);
  }

  21% {
    -webkit-transform: rotateZ(18deg);
  }

  23% {
    -webkit-transform: rotateZ(-16deg);
  }

  25% {
    -webkit-transform: rotateZ(14deg);
  }

  27% {
    -webkit-transform: rotateZ(-12deg);
  }

  29% {
    -webkit-transform: rotateZ(10deg);
  }

  31% {
    -webkit-transform: rotateZ(-8deg);
  }

  33% {
    -webkit-transform: rotateZ(6deg);
  }

  35% {
    -webkit-transform: rotateZ(-4deg);
  }

  37% {
    -webkit-transform: rotateZ(2deg);
  }

  39% {
    -webkit-transform: rotateZ(-1deg);
  }

  41% {
    -webkit-transform: rotateZ(1deg);
  }

  43% {
    -webkit-transform: rotateZ(0);
  }

  100% {
    -webkit-transform: rotateZ(0);
  }
}

@-moz-keyframes ring {
  0% {
    -moz-transform: rotate(0);
  }

  1% {
    -moz-transform: rotate(30deg);
  }

  3% {
    -moz-transform: rotate(-28deg);
  }

  5% {
    -moz-transform: rotate(34deg);
  }

  7% {
    -moz-transform: rotate(-32deg);
  }

  9% {
    -moz-transform: rotate(30deg);
  }

  11% {
    -moz-transform: rotate(-28deg);
  }

  13% {
    -moz-transform: rotate(26deg);
  }

  15% {
    -moz-transform: rotate(-24deg);
  }

  17% {
    -moz-transform: rotate(22deg);
  }

  19% {
    -moz-transform: rotate(-20deg);
  }

  21% {
    -moz-transform: rotate(18deg);
  }

  23% {
    -moz-transform: rotate(-16deg);
  }

  25% {
    -moz-transform: rotate(14deg);
  }

  27% {
    -moz-transform: rotate(-12deg);
  }

  29% {
    -moz-transform: rotate(10deg);
  }

  31% {
    -moz-transform: rotate(-8deg);
  }

  33% {
    -moz-transform: rotate(6deg);
  }

  35% {
    -moz-transform: rotate(-4deg);
  }

  37% {
    -moz-transform: rotate(2deg);
  }

  39% {
    -moz-transform: rotate(-1deg);
  }

  41% {
    -moz-transform: rotate(1deg);
  }

  43% {
    -moz-transform: rotate(0);
  }

  100% {
    -moz-transform: rotate(0);
  }
}

@keyframes ring {
  0% {
    transform: rotate(0);
  }

  1% {
    transform: rotate(30deg);
  }

  3% {
    transform: rotate(-28deg);
  }

  5% {
    transform: rotate(34deg);
  }

  7% {
    transform: rotate(-32deg);
  }

  9% {
    transform: rotate(30deg);
  }

  11% {
    transform: rotate(-28deg);
  }

  13% {
    transform: rotate(26deg);
  }

  15% {
    transform: rotate(-24deg);
  }

  17% {
    transform: rotate(22deg);
  }

  19% {
    transform: rotate(-20deg);
  }

  21% {
    transform: rotate(18deg);
  }

  23% {
    transform: rotate(-16deg);
  }

  25% {
    transform: rotate(14deg);
  }

  27% {
    transform: rotate(-12deg);
  }

  29% {
    transform: rotate(10deg);
  }

  31% {
    transform: rotate(-8deg);
  }

  33% {
    transform: rotate(6deg);
  }

  35% {
    transform: rotate(-4deg);
  }

  37% {
    transform: rotate(2deg);
  }

  39% {
    transform: rotate(-1deg);
  }

  41% {
    transform: rotate(1deg);
  }

  43% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(0);
  }
}

.margin-left-5 {
  margin-left: 5rem !important;
}

.maxHeight600px {
  max-height: 700px;
}

.checkbox-round {
  position: relative;
}

.checkbox-round label {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 30%;
  cursor: pointer;
  height: 28px;
  left: 0;
  position: absolute;
  top: 0;
  width: 28px;
}

.checkbox-round label:after {
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
  content: "";
  height: 6px;
  left: 7px;
  opacity: 0;
  position: absolute;
  top: 8px;
  transform: rotate(-45deg);
  width: 12px;
}

.checkbox-round input[type="checkbox"] {
  visibility: hidden;
}

.checkbox-round input[type="checkbox"]:checked+label {
  background-color: #87e0ec;
  border-color: #87e0ec;
}

.checkbox-round input[type="checkbox"]:checked+label:after {
  opacity: 1;
}

.detailList li {
  width: 33.333%;
}

#topZero fieldset {
  top: 0 !important;
}

.tooltipPositioning {
  right: -270px;
  left: 160px;
  border-radius: 12px;
}

.tooltipHeader {
  background: #051c2d;
}

.introjs-button {
  text-align: "center";
  display: block;
  padding: 7px 25px;
  background: transparent !important;
  /* hoverBg: "#0042a8"; */
  border: 1px solid #0042a8 !important;
  border-radius: 8px;
  color: #0042a8 !important;
  font-weight: 600;
}

.introjs-button:focus {
  box-shadow: 0 0 0 0.2rem #d54f2e37 !important;
}

.introjs-tooltip {
  overflow: hidden;
  border-radius: 12px !important;
}

.introjs-skipbutton {
  color: #fff !important;
}

.tooltipHeader,
.introjs-tooltip-header {
  background: #051c2d;
  padding-bottom: 10px;
}

.shimmer {
  color: grey;
  display: inline-block;
  -webkit-mask: linear-gradient(-60deg, #000 30%, #0005, #000 70%) right/300% 100%;
  background-repeat: no-repeat;
  animation: shimmer 2.5s infinite;
  font-size: 50px;
  max-width: 200px;
}

.category-icon {
  border: 1px solid transparent !important;
  object-fit: cover !important;
  border-radius: 30%;
}

.tree-styling .MuiTreeItem-content {
  background-color: white;
  padding: 8px;
  color: #e65300;
}

@keyframes shimmer {
  100% {
    -webkit-mask-position: left;
  }
}

.linked_document_main {
  width: 250px;
  background: #fff;
  border-radius: 8px;
  bottom: 40px;
  right: 15px;
  box-shadow: 0 1px 4px 0 rgb(0 0 0 / 14%);
}

.linked_document_header {
  background: #0042a8;
  color: #fff;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.linked_document_content {
  max-height: 120px;
  overflow: auto;
}

.linked_document_input_style {
  border: 1px solid #0042a8;
  width: -webkit-fill-available;
  border-radius: 8px;
  padding: 2px 12px;
  color: #000;
  font-size: 12px;
}

.linked_document_input_style::placeholder {
  color: #000;
}

::-webkit-scrollbar {
  width: 0;
}

/* .bgYellow {
  background: #ffb721;
} */

.otp-bg-white input {
  background: #fff !important;
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 4px;
}

.otp-bg-white fieldset {
  background: transparent !important;
  border: 0;
}

.padd_top {
  padding-top: 260px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

#txtPassword {
  -webkit-text-security: disc;
  -webkit-text-security: disc;
  /* For Safari */
  -moz-text-security: disc;
  /* For Firefox */
  text-security: disc;
  /* For other browsers that support the property */
}

.MuiButton-root.disabled_color.Mui-disabled {
  color: rgb(147, 146, 146) !important;
}

.bgYellow {
  /* background: #ffb721; */
}

.otp-bg-white input {
  background: #fff !important;
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 4px;
}

.otp-bg-white fieldset {
  background: transparent !important;
  border: 0;
}

.padd_top {
  padding-top: 260px;
}

.background-yellow {
  background-color: white !important;
}

.zoom {
  /* padding: 27px; */
  background-color: white;
  transition: transform 2.225s;
  /* Animation */
  width: 5px;
  height: 5px;
  border-radius: 50px;
  /* align-items: center; */
  /* justify-content: center; */
  /* display: flex; */
  /* margin: 0 auto; */
  margin-top: 50%;
  margin-left: 50%;
  position: "absolute";
}

.zoomIn {
  transform: scale(340.5);
}

.toolTipSubMenu {
  left: 7rem;
  top: 7rem;
  width: 20rem;
  z-index: 10000;
  position: absolute !important;
}

.introjs-tooltiptext {
  font-size: 14px;
  font-weight: 500;
}

.tooltip__a {
  position: relative;
  display: inline-block;
}

.tooltip__a .tooltiptext__a {
  visibility: hidden;
  width: 120px;
  background-color: #ffb722;
  color: white;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 14px;
  right: 70%;
  margin-left: -50px;
  font-weight: 400;
}

/* .tooltip__a .tooltiptext__a::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
} */

.tooltip__a:hover .tooltiptext__a {
  visibility: visible;
}

.overflow_hidden {
  overflow: hidden;
}

@media only screen and (max-width: 630px) {
  .overflow_hidden {
    overflow-x: scroll;
  }

  .alert_main {
    width: 630px;
  }

  .mx_xs_15px {
    margin: 0 32px;
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  [data-tooltip]:hover::before,
  [data-tooltip]:hover::after {
    display: none;
  }
}

@media only screen and (max-width: 850px) {

  [data-tooltip]:hover::before,
  [data-tooltip]:hover::after {
    display: none;
  }
}

@media only screen and (max-width: 389px) {
  .flex_col {
    flex-direction: column;
    align-items: flex-start !important;
  }
}

@media only screen and (max-width: 959px) {
  .dotsInMobile {
    position: absolute;
    top: 0;
    bottom: 0;
  }
}

.org-chart {
  display: block;
  clear: both;
  margin-bottom: 30px;
  position: relative;
}

.org-chart.cf:before,
.org-chart.cf:after {
  content: " ";
  display: table;
}

.org-chart.cf:after {
  clear: both;
}

.org-chart.cf {
  zoom: 1;
}

.org-chart ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.org-chart ul li {
  position: relative;
}

.org-chart ul li span.main-box {
  display: block;
  text-align: center;
  overflow: hidden;
  text-decoration: none;
  color: black;
  font-size: 12px;
  box-shadow: 4px 4px 9px -4px rgba(0, 0, 0, 0.4);
  -webkit-transition: all linear 0.1s;
  -moz-transition: all linear 0.1s;
  transition: all linear 0.1s;
  /* background: #92d4a8; */
  /* padding: 4px; */
}

.org-chart .lvl-b {
  background: #fff;
  color: #051C2D;
  border-radius: 8px;
}

.org-chart .board {
  width: 70%;
  margin: 0 auto;
  display: block;
  position: relative;
}

.org-chart .board:before {
  content: "";
  display: block;
  position: absolute;
  height: 200px;
  width: 0px;
  /* border-left: 2px solid orange; */
  border-left: 2px dashed #000;
  margin-left: 49%;
  top: 25px;
}

.org-chart ul.columnOne {
  height: 175px;
  position: relative;
  width: 100%;
  display: block;
  clear: both;
}

.org-chart ul.columnOne li {
  width: 170px;
  margin: 0px auto;
  /* top: 20px; */
}

.profile-image-main {
  width: 70px;
  height: 70px;
  border-radius: 100%;
  border: 1px solid #000;
  margin: 0 auto 12px;
}

.org-chart ul.columnTwo {
  position: relative;
  width: 100%;
  display: block;
  height: 90px;
  clear: both;
}

.org-chart ul.columnTwo li:first-child {
  width: 30%;
  float: left;
}

.org-chart ul.columnTwo li {
  width: 30%;
  float: right;
}

.org-chart ul.columnTwo:before {
  content: "";
  display: block;
  position: relative;
  width: 80%;
  height: 10px;
  border-top: 2px dashed #000;
  margin: 0 auto;
  top: 40px;
}

.org-chart ul.columnThree {
  position: relative;
  width: 100%;
  display: block;
  clear: both;
}

.org-chart ul.columnThree li:first-child {
  width: 30%;
  float: left;
  margin-left: 0;
}

.org-chart ul.columnThree li {
  width: 30%;
  margin-left: 5%;
  float: left;
}

.org-chart ul.columnThree li:last-child {
  width: 30%;
  float: right;
  margin-left: 0;
}

.org-chart ul.columnThree:before {
  content: "";
  display: block;
  position: relative;
  width: 80%;
  height: 10px;
  border-top: 2px dashed #000;
  margin: 0 auto;
  top: 40px;
}

.org-chart .departments {
  width: 100%;
  display: block;
  clear: both;
}

.org-chart .departments:before {
  content: "";
  display: block;
  width: 85%;
  height: 22px;
  border-top: 2px dashed #000;
  border-left: 2px dashed #000;
  border-right: 2px dashed #000;
  /* margin: 0 auto; */
  margin-left: 50px;
  top: 0px;
}

.org-chart .department {
  border-left: 2px dashed #000;
  width: 13%;
  float: left;
  margin: 0px 4px;
}

.org-chart .department:after {
  content: "";
  position: absolute;
  display: block;
  width: 10px;
  height: 22px;
  border-left: 2px dashed #000;
  left: 50%;
  top: -22px;
}

.org-chart .department:first-child:after {
  display: none;
}

.org-chart .department:last-child:after {
  display: none;
}

.org-chart .department.central {
  /* background: #f5eec9; */
}

.org-chart .department.central:after {
  display: none;
}

.org-chart .department span.main-box {
  /* border-left: 1px solid orange; */
}

.org-chart .department li {
  padding-left: 25px;
  border-bottom: 2px dashed #000;
  height: 95px;
}

.org-chart .department li span.main-box {
  background: #fff;
  top: 38px;
  position: absolute;
  z-index: 1;
  width: 95%;
  height: auto;
  vertical-align: middle;
  right: 0px;
  line-height: 14px;
  border-radius: 8px;
  /* border: 3px solid orange; */
}

.org-chart .department .sections {
  margin-top: -20px;
}

/* MEDIA QUERIES */
@media all and (max-width: 767px) {
  .org-chart .board {
    margin: 0px;
    width: 100%;
  }

  .org-chart .departments:before {
    border: none;
  }

  .org-chart .department {
    float: none;
    width: 100%;
    margin-left: 0;
    /* background: #f5eec9; */
    margin-bottom: 40px;
  }

  .org-chart .department:before {
    content: "";
    display: block;
    position: absolute;
    width: 15px;
    height: 60px;
    border-left: 2px dashed #000;
    z-index: 1;
    top: -45px;
    left: 0%;
    margin-left: -2px;
  }

  .org-chart .department:after {
    display: none;
  }

  .org-chart .department:first-child:before {
    display: none;
  }

  .org-chart .board:before {
    height: 145px;

  }
}

@media only screen and (max-width: 450px) {
  .addCollaborator {
    top: 50px!important;
    right: 0;
    left:-41px !important
  }
}
@media only screen and (max-width: 344px) {
  .addCollaborator {
    top: 50px!important;
    right: 0;
    left:-63px !important
  }
}
@media only screen and (max-width: 640px) {
  .pricing-font{
    font-size: 1.8rem !important;
  }
}
